<template>
  <div class="lg:w-96">
    <div
      v-if="data.title"
      class="md:heading-18-medium-px mb-4 text-heading-18-medium-px text-neutral-300 lg:mb-5 lg:text-heading-18-medium"
    >
      {{ data.title }}
    </div>
    <ul
      v-if="Array.isArray(data.menuItems) && data.menuItems.length"
      class="my-4 flex flex-col gap-6 lg:my-0 lg:min-w-[296px] lg:gap-2 xl:gap-4"
    >
      <li v-for="item in data.menuItems" :key="item.id">
        <NuxtLink
          :to="
            item.reference
              ? localePathByType(item.reference.__typename as string, item.reference.slug as string)
              : item.externalLink
          "
          noPrefetch
          class="group flex gap-x-3 rounded-xl transition-colors duration-300 md:-translate-x-3px md:px-3px md:py-2px md:hover:bg-neutral-50"
          @click="menuHide"
        >
          <div class="self-center">
            <Icon
              v-if="item.icon?.name"
              :name="item.icon?.name!.toLowerCase() as IconName"
              size="nav"
            />
          </div>
          <div>
            <span
              class="mb-1 block text-heading-18-medium transition-colors duration-300 md:text-heading-18-medium-px md:group-hover:text-orange lg:text-button-20-regular lg:font-medium"
            >
              {{ item.title }}
            </span>
            <span v-if="item.description" class="text-[15px] leading-[136%] text-neutral-800">
              {{ item.description }}
            </span>
          </div>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { MenuGroupRecord } from '~/types/generated'
import { IconName } from '~/components/media/Icon.vue'

const { localePathByType } = useLocalePathByType()
const { menuHide } = useMenu()

defineProps({
  data: {
    type: Object as PropType<MenuGroupRecord>,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  }
})
</script>
